'use client';

import { FC, useReducer } from 'react';

import Link from 'next/link';
import { IfTestEnvironment } from 'ui/IfTestEnvironment';

const Error: FC<{
  reset: () => void;
  error: Error & { digest?: string };
}> = ({ error }) => {
  const [visible, show] = useReducer(() => true, false);
  return (
    <div className="h-full w-full m-auto max-w-3xl text-center flex flex-col items-center justify-center p-6 overflow-auto">
      Ett fel har inträffat. Vänligen prova ladda om sidan, fall problem kvarstår
      kontakta support på
      <Link href="mailto:support@drhud.se" className="text-blue-500">
        support@drhud.se
      </Link>
      <IfTestEnvironment>
        {!visible ? (
          <div className="underline text-blue-500 my-5 cursor-pointer" onClick={show}>
            Reveal Error
          </div>
        ) : (
          <div className="text-red-500 bg-red-100 p-3 rounded-md mt-4">
            {error.message}
          </div>
        )}
      </IfTestEnvironment>
    </div>
  );
};

export default Error;
